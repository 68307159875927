<template>
  <div
    v-if="loading"
    class="flex justify-center items-center"
    style="height: 100vh"
  >
    <spinner-color />
  </div>
  <div class="main-div" @click="mainDiv" v-else>
    <div class="overlay-video" v-if="displayVideo"></div>
    <div class="display-video" v-if="displayVideo">
      <video width="560" height="315" controls autoplay>
        <source :src="vidId" type="video/mp4" />
        <source :src="vidId" type="video/ogg" />
        Your browser does not support HTML video.
      </video>
    </div>

    <div class="help-center">
      <h3 class="title">كيف يمكننا مساعدتك ؟</h3>
      <div class="input-search-article">
        <input
          id="input-search-article"
          v-model="search"
          @input="videoResult()"
          @click="showResult()"
          placeholder="البحث في مقالات أو مواضيع المساعدة …"
        />
        <i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.414"
            height="16.653"
            viewBox="0 0 16.414 16.653"
          >
            <g id="search" transform="translate(1 1)">
              <circle
                id="Ellipse_274"
                data-name="Ellipse 274"
                cx="6"
                cy="6"
                r="6"
                fill="none"
                stroke="#6e6b7b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
              <line
                id="Line_69"
                data-name="Line 69"
                x1="3.469"
                y1="3.469"
                transform="translate(10.531 10.77)"
                fill="none"
                stroke="#6e6b7b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </i>
        <div class="suggest-search">
          <template v-if="search != ''">
            <div
              class="the-result"
              v-for="(result, index) in resultOfSearch"
              :key="index"
              @click="playVid(result.url), check(result.id)"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.414"
                  height="16.653"
                  viewBox="0 0 16.414 16.653"
                >
                  <g id="search" transform="translate(1 1)">
                    <circle
                      id="Ellipse_274"
                      data-name="Ellipse 274"
                      cx="6"
                      cy="6"
                      r="6"
                      fill="none"
                      stroke="#6e6b7b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <line
                      id="Line_69"
                      data-name="Line 69"
                      x1="3.469"
                      y1="3.469"
                      transform="translate(10.531 10.77)"
                      fill="none"
                      stroke="#6e6b7b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </span>
              <div>
                <h6>{{ result.title }}</h6>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="parent-article" v-if="videos.length != 0">
        <div
          class="new-article"
          @click="(vidId = videos[0].url), (displayVideo = true)"
        >
          <img :src="videos[0].thumbnail" />
          <div class="overlay"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="67.391"
            height="74.45"
            viewBox="0 0 67.391 74.45"
          >
            <defs>
              <filter
                id="Icon_awesome-play"
                x="0"
                y="0"
                width="67.391"
                height="74.45"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood flood-opacity="0.102" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              transform="matrix(1, 0, 0, 1, 0, 0)"
              filter="url(#Icon_awesome-play)"
            >
              <path
                id="Icon_awesome-play-2"
                data-name="Icon awesome-play"
                d="M46.79,23.669,7.982.726A5.263,5.263,0,0,0,0,5.28V51.155a5.287,5.287,0,0,0,7.982,4.553L46.79,32.776A5.287,5.287,0,0,0,46.79,23.669Z"
                transform="translate(9 6)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <div class="articles">
          <div
            v-for="(video, index) in videos"
            :key="index"
            class="article flex"
            @click="(vidId = video.url), (displayVideo = true)"
          >
            <div class="img-article">
              <img :src="video.thumbnail" />
              <div class="overlay"></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="25"
                height="29"
                viewBox="0 0 67.391 74.45"
              >
                <defs>
                  <filter
                    id="Icon_awesome-play"
                    x="0"
                    y="0"
                    width="67.391"
                    height="74.45"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.102" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  transform="matrix(1, 0, 0, 1, 0, 0)"
                  filter="url(#Icon_awesome-play)"
                >
                  <path
                    id="Icon_awesome-play-2"
                    data-name="Icon awesome-play"
                    d="M46.79,23.669,7.982.726A5.263,5.263,0,0,0,0,5.28V51.155a5.287,5.287,0,0,0,7.982,4.553L46.79,32.776A5.287,5.287,0,0,0,46.79,23.669Z"
                    transform="translate(9 6)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div class="article-content">
              <h5>{{ video.title }}</h5>
              <p
                v-html="video.description"
                style="font-size: 16px; line-height: 29px; color: #6e6b7b"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="faq-header flex justify-between">
      <h4 class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mr-3"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            id="Path_1649"
            data-name="Path 1649"
            d="M452.2,187.375a9,9,0,1,0,9,9A9,9,0,0,0,452.2,187.375Zm0,16.594a7.594,7.594,0,1,1,7.594-7.594A7.59,7.59,0,0,1,452.2,203.969Zm2.813-9.264a2.817,2.817,0,0,1-2.109,2.723v1.144a.7.7,0,1,1-1.406,0v-1.758a.7.7,0,0,1,.7-.7,1.406,1.406,0,1,0-1.406-1.406.7.7,0,1,1-1.406,0,2.813,2.813,0,0,1,5.625,0Zm-1.933,5.977a.879.879,0,1,1-.879-.879A.879.879,0,0,1,453.083,200.682Z"
            transform="translate(-443.204 -187.375)"
            fill="#6e6b7b"
          />
        </svg>
        {{ $t("commonQuestions") }}
      </h4>
    </div>
    <vs-divider class="m-0 mb-6" />
    <div class="flex" style="column-gap: 37px; flex-direction: row-reverse;">
      <div class="faq-body">
        <div
          v-for="(f, index) in faq"
          :key="index + 'A'"
          :id="'all-faq' + f.id"
        >
          <div v-if="(index + 1) % 2 == 0" class="all-faq">
            <div
              class="faq-question"
              :class="{ 'faq-question-active': idFaq == f.id }"
              @click="check(f.id)"
            >
              <p>
                {{ f.question }}
              </p>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8.574"
                  viewBox="0 0 8 4.574"
                >
                  <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M14.442,10.195,11.414,7.17a.569.569,0,0,1,0-.807.577.577,0,0,1,.81,0l3.43,3.427a.571.571,0,0,1,.017.788L12.227,14.03a.572.572,0,1,1-.81-.807Z"
                    transform="translate(14.196 -11.246) rotate(90)"
                    fill="#212121"
                  />
                </svg>
              </i>
            </div>
            <div :class="idFaq == f.id ? 'active-faq-answer' : 'faq-answer'">
              <p v-html="f.answer">
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="faq-body">
        <div
          v-for="(f, index) in faq"
          :key="index + 'B'"
          :id="'all-faq' + f.id"
        >
          <div v-if="(index + 1) % 2 != 0" class="all-faq">
            <div
              class="faq-question"
              :class="{ 'faq-question-active': idFaq == f.id }"
              @click="check(f.id)"
            >
              <p>
                {{ f.question }}
              </p>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="8.574"
                  viewBox="0 0 8 4.574"
                >
                  <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M14.442,10.195,11.414,7.17a.569.569,0,0,1,0-.807.577.577,0,0,1,.81,0l3.43,3.427a.571.571,0,0,1,.017.788L12.227,14.03a.572.572,0,1,1-.81-.807Z"
                    transform="translate(14.196 -11.246) rotate(90)"
                    fill="#212121"
                  />
                </svg>
              </i>
            </div>
            <div :class="idFaq == f.id ? 'active-faq-answer' : 'faq-answer'">
              <p v-html="f.answer">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import SpinnerColor from "../components/table-components/SpinnerColor2.vue";

export default {
  data() {
    return {
      showAnswer: false,
      idFaq: 1,
      displayVideo: false,
      vidId: "",
      search: "",
      resultOfSearch: "",
      mergedArray: [],
      loading: false,
    };
  },
  components: {
    SpinnerColor,
  },
  computed: {
    ...mapGetters("dataList", ["faq", "videos"]),
  },
  methods: {
    ...mapActions("dataList", ["fetchFaq", "fetchVideos"]),
    check(id) {
      if (!this.displayVideo) {
        if (this.idFaq === id) {
          this.idFaq = null;
        } else {
          this.idFaq = id;
        }
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(`#all-faq${this.idFaq}`).offset().top - 150,
          },
          500
        );
      }
    },
    toggleFaq() {
      $(".faq-answer").slideToggle();
    },
    mainDiv(e) {
      const video = $(".display-video");
      if (e.srcElement === video[0]) {
        this.displayVideo = false;
      }
      const suggestSearch = $(".suggest-search");
      const inputSuggest = $("#input-search-article");
      if (e.target !== suggestSearch[0] && e.target !== inputSuggest[0]) {
        suggestSearch.slideUp();
      }
    },
    showResult() {
      $(".suggest-search").slideDown();
    },
    videoResult() {
      this.resultOfSearch = this.mergedArray.filter((mergedArray) =>
        mergedArray.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    playVid(vidId) {
      if (vidId) {
        this.displayVideo = true;
        this.vidId = vidId;
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.check(this.$route.query.id);
    }
  },
  async created() {
    this.loading = true;
    this.mergedArray = await this.faq.concat(this.videos);
    if (this.$route.params.name === "faq") {
      $([document.documentElement, document.body]).animate(
        { scrollTop: 520 },
        500
      );
    } else {
      this.playVid(this.$route.query.vidId);
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
.help-center {
  P {
    font-size: 16px;
    line-height: 29px;
    color: #6e6b7b;
    font-weight: 600;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/vuexy/_suggestSearch.scss";

.overlay-video {
  position: fixed;
  width: 100%;
  height: 108.4%;
  top: 0px;
  left: 0px;
  background-color: #0006;
  z-index: 99999;
}
.display-video {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 999999;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.help-center {
  margin-bottom: 24px;
}
.help-center h3 {
  color: #212121;
  font-size: 24px;
  line-height: 47px;
  margin-bottom: 24px;
  font-weight: bold;
  text-align: center;
}
.input-search-article {
  width: 65%;
  position: relative;
  // text-align: center;
  margin: auto;
  margin-bottom: 32px;
  input {
    width: 100%;
    padding: 13px 24px;
    box-shadow: 0px 5px 24px #31006f0d;
    border: none;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    color: #212121;
    line-height: 35px;
    &::placeholder {
      color: #bfbfbf;
    }
  }
  i {
    position: absolute;
    right: 15px;
    top: 22px;
  }
}

.parent-article {
  gap: 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "item1 item1 item1 item1 item2 item2 item2 item2 item2 item2 item2 item2 ";
}

.new-article,
.articles {
  position: relative;
  height: 320px;
  cursor: pointer;
  grid-area: item1;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
.articles {
  grid-area: item2;
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  height: fit-content;
}
.overlay {
  position: absolute;
  opacity: 60%;
  background-image: linear-gradient(180deg, #31006f41, #31006f);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  mix-blend-mode: multiply;
}

.new-article {
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
  .overlay {
    border-radius: 12px;
  }
}

.article {
  .img-article {
    cursor: pointer;
    width: 88px;
    height: 88px;
    position: relative;
    margin-right: 24px;
    img {
      margin-right: 24px;
      border-radius: 8px;
      width: 88px;
      height: 100%;
    }
  }

  .overlay {
    border-radius: 8px;
  }
  .article-content {
    h5 {
      color: #212121;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 2.5px;
      font-weight: bold;
    }
    P {
      font-size: 16px;
      line-height: 29px;
      color: #6e6b7b;
    }
  }
}

.faq-header {
  margin-bottom: 1.125rem;
  h4 {
    color: #212121;
    font-size: 1.125rem;
    line-height: 2.9375rem;
    font-weight: bold;
  }
}
.faq-body {
  width: 50%;
  .all-faq {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .faq-question {
    width: 100%;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0rem 0.25rem 1.5rem #0000000d;
    padding: 1.125rem 1.5rem;
    cursor: pointer;
    position: relative;
    z-index: 1;
    p {
      color: #000000;
      font-size: 1rem;
      font-weight: bold;
      padding-right: 16px;
    }
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5rem;
      transition: 0.3s;
    }
  }
  .faq-question-active {
    border: solid 0.0625rem #d8d6de;
    p {
      color: $mainColor1;
    }
    i {
      transform: rotate(180deg);
      transition: 0.3s;
      top: 35%;
    }
  }
  .faq-answer,
  .active-faq-answer {
    background-color: $mainColor2;
    border: solid 0.0625rem #d8d6de;
    transform: translateY(-0.375rem);
    p {
      color: #000000;
      font-size: 0.875rem;
      line-height: 1.875rem;
      font-weight: bold;
    }
  }
  .faq-answer {
    padding: 0 1.5rem;
    opacity: 0;
    height: 0rem;
    transition: 0.3s;
  }
  .active-faq-answer {
    padding: 1.1875rem 1.5rem;
    opacity: 1;
    height: auto;
    transition: 0.3s;
  }
}
</style>
